@import '@crystal-eyes/helpers.scss';
@import '@crystal-eyes/constants.scss';

.creditLedgerDrawer {
  width: calc(100% - 378px);
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  right: 0;
  margin: 15px 15px 0;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: var(--font-lato), Lato;

  @media (max-width: $mobile-width) {
    width: calc(100% - 50px);
    margin: 0;
  }

  .iconWrapper {
    cursor: pointer;
    &:hover {
      opacity: 0.87;
    }
  }

  .icon {
    @include svg-bold();
    color: $action-blue;
    margin-left: 4px;
    height: 20px;
  }

  .table {
    width: 100%;
  }
}
